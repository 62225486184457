<template>
  <panel
    :title="title"
    :breadcrumbs="breadcrumbs"
  >
    <v-toolbar
      card
      height="100px"
      tile
    >
      <v-container fluid>
        <v-layout row>
          <v-menu
            v-model="menu"
            close-on-content-click
            transition="scale-transition"
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="monthOf"
              prepend-icon="event"
              readonly
            />
            <v-date-picker
              v-model="monthOf"
              :allowed-dates="allowedMonths"
              min="2018-01"
              max="2023-07"
              type="month"
              year-icon="date_range"
            />
          </v-menu>
          <v-spacer />
          <v-flex
            xs12
            sm6
          >
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              solo-inverted
              clearable
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :rows-per-page-items="[10, 25, 50, 100]"
          :expand="true"
          class="px-1 pb-1"
        >
          <template #items="props">
            <tr @click="props.expanded = !props.expanded">
              <td
                v-if="props.item.msisdn"
                class="text-xs-left"
              >
                {{ props.item.msisdn }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.name"
                class="text-xs-left"
              >
                {{ props.item.name }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.donor"
                class="text-xs-left"
              >
                {{ props.item.donor }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.date"
                class="text-xs-left"
              >
                {{ props.item.date }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
            </tr>
          </template>
          <template slot="no-data">
            <v-alert
              :value="true"
              :type="error ? 'error' : 'info'"
            >
              {{ noDataText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const pageTitle = 'Mnp Non-Celcom'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Campaigns', disabled: false, to: '/campaigns',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      menu: false,
      monthOf: null,
      // maxDate: '',
      title: pageTitle,
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Donor',
          value: 'donor',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Date',
          value: 'date',
        },
      ],
      items: [],
      loading: false,
      search: '',
      totalItems: 0,
      pagination: {},
      error: null,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    monthOf: function (val) {
      this.getMnpNonCelcom(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.monthOf = now.toFormat('yyyy-LL')
  },

  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getMnpNonCelcom (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getMnpCelcom.php', params)
        .then((response) => {
          this.items = response.data.items
          this.loading = true
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
